:root {
  /* Bootstrap 5 and Kendo for Angular Color Variables */

  /* Text and Body Colors */
  --bs-body-color: #404040; /* Source: default-text-color */
  --bs-body-bg: #ffffff; /* Reasonable default for background color */
  --bs-heading-color: #69737a; /* Source: default-text-heading-color */
  --bs-text-muted: #ddd; /* Source: default-text-disabled-color */
  --bs-link-color: #6aa2b8; /* Source: link-default-color */
  --bs-link-hover-color: #9ec2d1; /* Source: link-hover-color */

  /* Font Families and Typography Variables for Bootstrap 5 and Kendo UI for Angular */

  /* Bootstrap Font Variables */
  --bs-font-sans-serif: "Open Sans", sans-serif; /* Source: primary-font-family */
  --bs-font-monospace: "Courier New", monospace; /* Reasonable default for monospace font */
  --bs-font-size-base: 0.875rem; /* Source: paragraph-default-font-size */
  --bs-font-size-lg: 1.125rem; /* Source: h2-font-size */
  --bs-font-size-sm: 0.75rem; /* Source: h6-font-size */
  --bs-font-weight-base: 400; /* Source: default-font-weight */
  --bs-font-weight-bold: 600; /* Source: h1-font-weight */
  --bs-body-font-family: "Open Sans", sans-serif; /* Source: primary-font-family */
  --bs-body-font-size: 0.875rem; /* Source: paragraph-default-font-size */
  --bs-body-font-weight: 400; /* Source: default-font-weight */
  --bs-body-line-height: 1.5; /* Reasonable default line height */
  --bs-body-color: #404040; /* Source: default-text-color */
  --bs-body-bg: #ffffff; /* Standard background color */

  /* Heading Font Sizes and Weights */
  --bs-h1-font-size: 1.5rem; /* Source: h1-font-size */
  --bs-h2-font-size: 1.125rem; /* Source: h2-font-size */
  --bs-h3-font-size: 1.375rem; /* Source: h3-font-size */
  --bs-h4-font-size: 1.125rem; /* Source: h4-font-size */
  --bs-h5-font-size: 0.875rem; /* Source: h5-font-size */
  --bs-h6-font-size: 0.75rem; /* Source: h6-font-size */
  --bs-h1-font-weight: 600; /* Source: h1-font-weight */
  --bs-h2-font-weight: 600; /* Source: h1-font-weight */
  --bs-h3-font-weight: 600; /* Source: h1-font-weight */
  --bs-h4-font-weight: 400; /* Source: default-font-weight */
  --bs-h5-font-weight: 400; /* Source: default-font-weight */
  --bs-h6-font-weight: 400; /* Source: default-font-weight */

  /* Kendo UI for Angular Font Variables */
  --kendo-font-family: "Open Sans", sans-serif; /* Source: primary-font-family */
  --kendo-heading-font-family: "Open Sans", sans-serif; /* Source: primary-font-family */
  --kendo-font-size: 0.875rem; /* Source: paragraph-default-font-size */
  --kendo-heading-font-size: 1.5rem; /* Source: h1-font-size */
  --kendo-font-weight: 400; /* Source: default-font-weight */
  --kendo-heading-font-weight: 600; /* Source: h1-font-weight */
  --kendo-line-height: 1.5; /* Reasonable default line height */
  --kendo-body-color: #404040; /* Source: default-text-color */
  --kendo-heading-color: #69737a; /* Source: default-text-heading-color */

  /* Primary, Secondary, and Brand Colors */
  --bs-primary: #457b91; /* Source: primary-brand-color */
  --bs-secondary: #eb7203; /* Source: secondary-brand-color */
  --bs-success: #5cb85c; /* Source: success-color */
  --bs-info: #98d1dd; /* Source: tertiary-brand-color */
  --bs-warning: #fda657; /* Source: text-focus-highlight-color */
  --bs-danger: #bd0026; /* Source: danger-color */
  --bs-light: #f8f9fa; /* Reasonable light background color */
  --bs-dark: #404040; /* Source: default-text-color */
  --bs-white: #ffffff; /* Standard for white color */
  --bs-black: #000000; /* Standard for black color */

  /* Bootstrap Background Colors */
  --bs-bg-primary: #457b91; /* Source: primary-brand-color */
  --bs-bg-secondary: #eb7203; /* Source: secondary-brand-color */
  --bs-bg-success: #5cb85c; /* Source: success-color */
  --bs-bg-info: #98d1dd; /* Source: tertiary-brand-color */
  --bs-bg-warning: #fda657; /* Source: text-focus-highlight-color */
  --bs-bg-danger: #bd0026; /* Source: danger-color */
  --bs-bg-light: #f8f9fa; /* Reasonable light background color */
  --bs-bg-dark: #404040; /* Source: default-text-color */
  --bs-bg-white: #ffffff; /* Standard white color */
  --bs-bg-transparent: transparent; /* Standard transparent value */

  /* Kendo UI for Angular Colors */
  --kendo-primary-color: #457b91; /* Source: primary-brand-color */
  --kendo-accent-color: #eb7203; /* Source: secondary-brand-color */
  --kendo-bg-color: #ffffff; /* Reasonable background color */
  --kendo-text-color: #404040; /* Source: default-text-color */
  --kendo-border-color: #7a868c; /* Source: primary-brand-color-2 */
  --kendo-hover-color: #9ec2d1; /* Source: link-hover-color */
  --kendo-selected-bg: #fda657; /* Source: text-focus-highlight-color */
  --kendo-selected-text-color: #404040; /* Source: default-text-color */
  --kendo-disabled-bg: #ddd; /* Source: default-text-disabled-color */
  --kendo-disabled-text-color: #ddd; /* Source: default-text-disabled-color */

  /* Button Colors */
  --bs-btn-color: #ffffff; /* Reasonable default for button text */
  --bs-btn-bg: #457b91; /* Source: primary-brand-color */
  --bs-btn-border-color: #457b91; /* Source: primary-brand-color */
  --bs-btn-hover-bg: #6aa2b8; /* Source: link-default-color */
  --bs-btn-hover-border-color: #6aa2b8; /* Source: link-default-color */
  --bs-btn-disabled-bg: #ddd; /* Source: default-text-disabled-color */
  --bs-btn-disabled-text-color: #ffffff; /* Reasonable contrast for disabled state */
  --bs-btn-border-radius: 0.25rem; /* Standard Bootstrap button border radius */

  /* Alert Colors */
  --bs-alert-color: #404040; /* Source: default-text-color */
  --bs-alert-bg: #f8f9fa; /* Reasonable background color */
  --bs-alert-border-color: #457b91; /* Source: primary-brand-color */
  --bs-alert-link-color: #6aa2b8; /* Source: link-default-color */

  /* Card Colors */
  --bs-card-bg: #ffffff; /* Standard white background for cards */
  --bs-card-border-color: #7a868c; /* Source: primary-brand-color-2 */
  --bs-card-title-color: #69737a; /* Source: default-text-heading-color */
  --bs-card-text-color: #404040; /* Source: default-text-color */

  /* Tooltip Colors */
  --bs-tooltip-bg: #404040; /* Source: default-text-color for contrast */
  --bs-tooltip-color: #ffffff; /* Standard white text for readability on dark background */
  --kendo-tooltip-bg-color: #404040; /* Source: default-text-color for contrast */
  --kendo-tooltip-text-color: #ffffff; /* Standard white text for readability on dark background */

  /* Dropdown Colors */
  --kendo-dropdown-bg-color: #ffffff; /* Standard white background */
  --kendo-dropdown-border-color: #7a868c; /* Source: primary-brand-color-2 */
  --kendo-dropdown-item-hover-bg: #9ec2d1; /* Source: link-hover-color */
  --kendo-dropdown-item-selected-bg: #fda657; /* Source: text-focus-highlight-color */
  --kendo-dropdown-item-text-color: #404040; /* Source: default-text-color */

  /* Modal and Dialog Colors */
  --bs-modal-bg: #ffffff; /* Standard white background */
  --bs-modal-header-bg: #457b91; /* Source: primary-brand-color */
  --bs-modal-footer-bg: #f8f9fa; /* Reasonable footer background color */
  --kendo-dialog-bg-color: #ffffff; /* Standard white background */
  --kendo-dialog-header-bg: #457b91; /* Source: primary-brand-color */
  --kendo-dialog-header-text-color: #ffffff; /* White text for contrast on primary-brand-color */
  --kendo-dialog-footer-bg: #f8f9fa; /* Reasonable footer background color */
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

/**
 * Micro frontend container styles
 */
.spa-container {
  display: flex;
  height: 80vh; /* Fills the viewport height */
}

.menu {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  padding: 10px;
  width: 305px;
  z-index: 1; /* Ensures menu layers above content when needed */
}

.content {
  display: flex;
  flex-direction: column; /* Allows further layout flexibility inside content */
  flex-grow: 1;
}

#mfe-container-root {
  padding: 0 15px;
}

/**
 * Loading overlay styles
 */
@keyframes loading {
  from {left: 50%; width: 0;z-index:100;}
  33.3333% {left: 0; width: 100%;z-index: 10;}
  to {left: 0; width: 100%;}
}

#initialization-banner {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  width: 100%;
  z-index: 1000;

  &.fade-out {
    opacity: 0;
    visibility: hidden;
  }

  & #splash {
    background-image: url('../images/AclaraONE.png');
    height: 480px;
    width: 534px;
    margin: auto;
    background-repeat: no-repeat;
    background-position-y: 170px;
  
    & .box {
      height: 260px;  
    }
  
    & .text {
      margin-top: 8px;
      margin-left: 8px;    
    }
  
    & .load-bar {
      position: relative;
      margin-top: 20px;
      width: 100%;
      height: 6px;
      background-color: #7a868c;
    }
  
    & .bar {
      content: "";
      display: inline;
      position: absolute;
      width: 0;
      height: 100%;
      left: 50%;
      text-align: center;
  
      &:nth-child(1) {
        background-color: #6aa2b8;
        animation: loading 3s linear infinite;
      }
  
      &:nth-child(2) {
        background-color: #7a868c;
        animation: loading 3s linear 1s infinite;
      }
  
      &:nth-child(3) {
        background-color: #eb7203;
        animation: loading 3s linear 2s infinite;
      }
    }
  }
}

.breadcrumbs-placeholder {
  margin: 10px 0 10px 0;
  padding: 8px 0;
  list-style: none;
  border-radius: 4px;
}
